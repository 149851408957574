type TokenPayload = {
  exp: number
  sub: `${number}`
}

export function parseJwt(token?: string): TokenPayload | null {
  if (!token) return null
  const parts = token.split('.')
  if (parts[1]) {
    try {
      return JSON.parse(atob(parts[1])) as TokenPayload
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
      return null
    }
  }
  return null
}

export function checkAccessToken(token?: string): boolean {
  if (!token) return false
  const nowUnix = (+new Date() / 1e3) | 0
  const decodedToken = parseJwt(token)
  if (decodedToken === null) return false
  return decodedToken.exp > nowUnix
}

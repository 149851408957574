import { concurrency, createQuery, keepFresh } from '@farfetched/core'
import type { EventPayload } from 'effector'
import { combine, createEvent, sample } from 'effector'
import { createAccessServiceEffect } from '~/api/access'
import { getMeBaseFx } from '~/api/auth.effects'
import { createManageServiceEffect } from '~/api/manage'
import { toggleFavoriteChannelMutation } from '~/features/channel/toggle-favorite/model'

export const initUser = createEvent()

export const currentUserAccountQuery = createQuery({
  effect: getMeBaseFx,
})

concurrency(currentUserAccountQuery, {
  strategy: 'TAKE_LATEST',
})

export const currentUserChannelsQuery = createQuery({
  effect: createManageServiceEffect({
    method: 'get',
    url: '/channels',
  }),
  initialData: [],
})

concurrency(currentUserChannelsQuery, {
  strategy: 'TAKE_LATEST',
})

export const currentUserChannelsAccessesQuery = createQuery({
  effect: createAccessServiceEffect({
    method: 'get',
    url: '/accesses',
  }),
  initialData: [],
})

concurrency(currentUserChannelsAccessesQuery, {
  strategy: 'TAKE_LATEST',
})

export const $currentUserChannelsWithAccesses = combine(
  [currentUserChannelsQuery.$data, currentUserChannelsAccessesQuery.$data],
  ([userChannels, accesses]) => {
    return userChannels.map(({ channel, ...user }) => ({
      ...user,
      channel: {
        ...channel,
        access:
          accesses.find(access => access.channel_id === channel.id) ?? null,
      },
    }))
  },
)

export const $currentUserChannelsRoles = combine(
  [currentUserChannelsQuery.$data],
  ([userChannels]) => {
    return userChannels.map(({ channel: { id }, role }) => ({ id, role }))
  },
)

export const $currentUserId = currentUserAccountQuery.$data.map(
  account => account?.id ?? null,
)

keepFresh(currentUserChannelsAccessesQuery, {
  triggers: [toggleFavoriteChannelMutation.finished.success],
})

sample({
  clock: currentUserChannelsQuery.$data,
  fn: userChannels =>
    ({
      params: {
        query: {
          channels: userChannels.map(({ channel: { id } }) => id),
        },
      },
    }) satisfies EventPayload<typeof currentUserChannelsAccessesQuery.start>,
  target: currentUserChannelsAccessesQuery.start,
})

sample({
  clock: initUser,
  fn: () =>
    ({}) satisfies EventPayload<
      | typeof currentUserAccountQuery.start
      | typeof currentUserChannelsQuery.start
    >,
  target: [
    currentUserAccountQuery.start,
    currentUserChannelsQuery.start,
  ] as const,
})

export const $isUserLoaded = combine(
  [
    currentUserAccountQuery.$status,
    currentUserChannelsQuery.$status,
    currentUserChannelsAccessesQuery.$status,
  ],
  ([accountStatus, channelsStatus, accessesStatus]) => {
    return (
      (accountStatus === 'done' || accountStatus === 'fail') &&
      (channelsStatus === 'done' || channelsStatus === 'fail') &&
      (accessesStatus === 'done' || accessesStatus === 'fail')
    )
  },
)

export const $userRegisteredAt = combine(
  [currentUserAccountQuery.$data],
  ([userData]) => {
    return userData?.created_at
  },
  { skipVoid: false },
)

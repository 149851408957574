import { onAbort } from '@farfetched/core'
import { createEffect } from 'effector'
import type { FetchResponse, MaybeOptionalInit } from 'openapi-fetch'
import createClient from 'openapi-fetch'
import type {
  HttpMethod,
  MediaType,
  PathsWithMethod,
} from 'openapi-typescript-helpers'
import type { paths } from './access-service.generated'
import { HOST } from './config'
import { authFetchFx } from './fetcher'

const accessAuthClient = createClient<paths>({
  baseUrl: `${HOST}/api/proxy/access-service`,
  fetch: authFetchFx,
})

export const createAccessServiceEffect = <
  Media extends MediaType,
  Method extends HttpMethod,
  Path extends PathsWithMethod<paths, Method>,
  Init extends MaybeOptionalInit<paths[Path], Method>,
  Response extends Required<
    FetchResponse<NonNullable<paths[Path][Method]>, Init, Media>
  >,
>({
  method,
  url,
}: {
  method: Method
  url: Path
}) =>
  createEffect(async (init: Init) => {
    const abortController = new AbortController()

    onAbort(() => {
      abortController.abort()
    })

    if (init) {
      init.signal = abortController.signal
    }

    const METHOD = method.toUpperCase() as keyof typeof accessAuthClient

    const fn = accessAuthClient[METHOD] as unknown as (
      url: Path,
      init: Init,
    ) => Promise<Response>

    const { error, data } = await fn(url, init)

    if (error) {
      throw error
    }

    return data
  })

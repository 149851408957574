import { fetchFx } from '~/api/fetch'
import type { ApiType } from '~/api/types'
import { ACCESS_TOKEN_STORAGE_KEY } from '~/constants'
import { createRefreshRequest } from './create-refresh-request'
import { checkAccessToken, parseJwt } from './jwt'

let refreshRequest: Promise<ApiType<'TokenSchema'>> | null = null

export const authFetch = (async (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
) => {
  const request = new Request(input, init)

  const isServer = typeof window === 'undefined'
  const originalRequest = request.clone()

  if (isServer) {
    const cookiesModule = await import('next/headers').then(mod => mod.cookies)
    const cookies = await cookiesModule()
    const accessToken = cookies.get(ACCESS_TOKEN_STORAGE_KEY)?.value ?? ''
    const isNotExpired = checkAccessToken(accessToken)

    if (isNotExpired) {
      originalRequest.headers.append('Authorization', `Bearer ${accessToken}`)
    } else {
      return Promise.reject('Expired access token')
    }

    return fetchFx(originalRequest)
  } else {
    const Cookies = await import('js-cookie').then(mod => mod.default)
    const currentAccessToken = Cookies.get(ACCESS_TOKEN_STORAGE_KEY) ?? ''
    const isNotExpired = checkAccessToken(currentAccessToken)

    if (isNotExpired) {
      originalRequest.headers.append(
        'Authorization',
        `Bearer ${currentAccessToken}`,
      )
    } else {
      Cookies.remove(ACCESS_TOKEN_STORAGE_KEY, {
        path: '/',
      })

      if (refreshRequest) {
        try {
          const { access_token } = await refreshRequest

          Cookies.set(ACCESS_TOKEN_STORAGE_KEY, access_token, {
            path: '/',
            expires: new Date((parseJwt(access_token)?.exp ?? 0) * 1000),
          })

          originalRequest.headers.append(
            'Authorization',
            `Bearer ${access_token}`,
          )
        } catch (err) {
          return Promise.reject(err)
        } finally {
          refreshRequest = null
        }
      } else {
        refreshRequest = createRefreshRequest()

        try {
          const { access_token } = await refreshRequest

          Cookies.set(ACCESS_TOKEN_STORAGE_KEY, access_token, {
            path: '/',
            expires: new Date((parseJwt(access_token)?.exp ?? 0) * 1000),
          })

          originalRequest.headers.append(
            'Authorization',
            `Bearer ${access_token}`,
          )
        } catch (err) {
          return Promise.reject(err)
        } finally {
          refreshRequest = null
        }
      }
    }

    const response = await fetchFx(originalRequest)

    if (!response.ok) {
      if (
        (response.status === 401 || response.status === 403) &&
        !response.url.includes('/next-api/auth')
      ) {
        if (refreshRequest) {
          try {
            const { access_token } = await refreshRequest

            Cookies.set(ACCESS_TOKEN_STORAGE_KEY, access_token, {
              path: '/',
              expires: new Date((parseJwt(access_token)?.exp ?? 0) * 1000),
            })

            originalRequest.headers.append(
              'Authorization',
              `Bearer ${access_token}`,
            )

            return fetchFx(originalRequest)
          } catch (err) {
            return Promise.reject(err)
          } finally {
            refreshRequest = null
          }
        } else {
          refreshRequest = createRefreshRequest()

          try {
            const { access_token } = await refreshRequest

            Cookies.set(ACCESS_TOKEN_STORAGE_KEY, access_token, {
              path: '/',
              expires: new Date((parseJwt(access_token)?.exp ?? 0) * 1000),
            })

            originalRequest.headers.append(
              'Authorization',
              `Bearer ${access_token}`,
            )

            return fetchFx(originalRequest)
          } catch (err) {
            return Promise.reject(err)
          } finally {
            refreshRequest = null
          }
        }
      } else {
        return Promise.reject(response)
      }
    } else {
      return response
    }
  }
}) as typeof fetch

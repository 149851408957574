import type {
  FetchOptions as _FetchOptions,
  FetchResponse as _FetchResponse,
} from 'openapi-fetch'
import createClient from 'openapi-fetch'
import type { HttpMethod } from 'openapi-typescript-helpers'
import type { MergeDeep } from 'type-fest'
import type * as AccessService from './access-service.generated'
import type * as Analysis from './analysis.generated'
import type * as Auth from './auth.generated'
import { HOST } from './config'
import { authFetchFx, publicFetchFx } from './fetcher'
import type * as Manage from './manage.generated'

const authPublicClient = createClient<Auth.paths>({
  baseUrl: `${HOST}/api/proxy/auth-service`,
  fetch: publicFetchFx,
})

const authAuthClient = createClient<Auth.paths>({
  baseUrl: `${HOST}/api/proxy/auth-service`,
  fetch: authFetchFx,
})

const managePublicClient = createClient<Manage.paths>({
  baseUrl: `${HOST}/api/proxy/manage-service`,
  fetch: publicFetchFx,
})

const manageAuthClient = createClient<Manage.paths>({
  baseUrl: `${HOST}/api/proxy/manage-service`,
  fetch: authFetchFx,
})

const analysisPublicClient = createClient<Analysis.paths>({
  baseUrl: `${HOST}/api/proxy/analysis-service`,
  fetch: publicFetchFx,
})

const analysisAuthClient = createClient<Analysis.paths>({
  baseUrl: `${HOST}/api/proxy/analysis-service`,
  fetch: authFetchFx,
})

const accessAuthClient = createClient<AccessService.paths>({
  baseUrl: `${HOST}/api/proxy/access-service`,
  fetch: authFetchFx,
})

export const API = {
  auth: {
    public: authPublicClient,
    auth: authAuthClient,
  },
  manage: {
    public: managePublicClient,
    auth: manageAuthClient,
  },
  analysis: {
    public: analysisPublicClient,
    auth: analysisAuthClient,
  },
  accessService: {
    auth: accessAuthClient,
  },
} as const

type paths = MergeDeep<
  MergeDeep<MergeDeep<Auth.paths, Manage.paths>, Analysis.paths>,
  AccessService.paths
>

type Path = keyof paths

export type FetchOptions<M extends HttpMethod, P extends Path> = _FetchOptions<
  paths[P][M]
>
